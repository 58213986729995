import Joi from "joi";
import * as MChat from "./m-chat-iife-types";

const defaultActionMenuStyling: Partial<CSSStyleDeclaration> = {
  position: "fixed",
  bottom: "0",
  right: "100px",
  width: "300px",
  height: "fit-content",
};

const defaultActionMenuCardBindings = {
  elevation: 5,
  class: ["rounded-b-0", "rounded-t-lg"],
};

export const selectorValidator = Joi.string().optional().default("body");
export const actionMenuStylingValidator = Joi.object<
  Partial<CSSStyleDeclaration>
>()
  .optional()
  .default(defaultActionMenuStyling);
export const allowLoginWithoutAuthValidator = Joi.boolean()
  .optional()
  .default(false);
export const debugValidator = Joi.boolean().optional().default(false);
export const serviceWorkerPathValidator = Joi.string()
  .optional()
  .default("/m-iife-sw.js");
export const actionMenuCardBindingsValidator = Joi.object<any>()
  .optional()
  .default(defaultActionMenuCardBindings);
export const chatHeadSizeValidator = Joi.number().optional().default(40);
export const showByDefaultValidator = Joi.boolean().optional().default(true);

export const mChatIIFEConfigOptionsSchemaValidators = {
  selector: selectorValidator,
  actionMenuStyling: actionMenuStylingValidator,
  allowLoginWithoutAuth: allowLoginWithoutAuthValidator,
  debug: debugValidator,
  serviceWorkerPath: serviceWorkerPathValidator,
  actionMenuCardBindings: actionMenuCardBindingsValidator,
  chatHeadSize: chatHeadSizeValidator,
  showByDefault: showByDefaultValidator,
};

export const mChatIIFEConfigOptionsSchema =
  Joi.object<MChat.MChatIIFEConfigOptions>(
    mChatIIFEConfigOptionsSchemaValidators,
  );
