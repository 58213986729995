<template>
  <v-card v-bind="wrapperBindings" @click="doFocus">
    <v-toolbar
      flat
      height="30"
      color="surface"
      rounded="t-lg"
      @mousedown="onMouseDownOnToolbar"
    >
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          density="compact"
          icon="mdi-image-size-select-small"
          title="Minimize"
          @click="doMinimize"
        />
        <v-btn
          density="compact"
          icon="mdi-close"
          title="Close"
          @click="doClose"
        />
      </v-toolbar-items>
    </v-toolbar>
    <v-divider />
    <div v-bind="chatWrapperBindings">
      <MalarkeyChat ref="chat" :sid="sid" class="w-100 h-100" />
    </div>
    <div
      class="m-iife-chat-resize-handle m-iife-chat-resize-handle-top"
      @mousedown="onMouseDownOnResizeTop"
    ></div>
    <div
      class="m-iife-chat-resize-handle m-iife-chat-resize-handle-left"
      @mousedown="onMouseDownOnResizeLeft"
    ></div>
    <div
      class="m-iife-chat-resize-handle m-iife-chat-resize-handle-bottom"
      @mousedown="onMouseDownOnResizeBottom"
    ></div>
    <div
      class="m-iife-chat-resize-handle m-iife-chat-resize-handle-right"
      @mousedown="onMouseDownOnResizeRight"
    ></div>
    <div
      class="m-iife-chat-resize-handle m-iife-chat-resize-handle-top-left"
      @mousedown="onMouseDownOnResizeTopLeft"
    ></div>
    <div
      class="m-iife-chat-resize-handle m-iife-chat-resize-handle-top-right"
      @mousedown="onMouseDownOnResizeTopRight"
    ></div>
    <div
      class="m-iife-chat-resize-handle m-iife-chat-resize-handle-bottom-left"
      @mousedown="onMouseDownOnResizeBottomLeft"
    ></div>
    <div
      class="m-iife-chat-resize-handle m-iife-chat-resize-handle-bottom-right"
      @mousedown="onMouseDownOnResizeBottomRight"
    ></div>
  </v-card>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  ref,
  onMounted,
  onBeforeUnmount,
  nextTick,
  watch,
  inject,
} from "vue";
import { useDisplay } from "vuetify";
import { useViewport } from "@nhtio/malarkey-client-core/utilities/vue-ui";
import { getDebugger } from "@jakguru/vueprint/utilities/debug";
import type { PropType } from "vue";
import type { VApp } from "vuetify/lib/components/VApp/index.mjs";
import type { BusService } from "@jakguru/vueprint";
import type MalarkeyChat from "@nhtio/malarkey-client-core/components/MalarkeyChat";

const debug = getDebugger("Chat Window", "#E34C26", "#FFFFFF");

export default defineComponent({
  name: "ChatWindow",
  props: {
    sid: {
      type: String,
      required: true,
    },
    minimized: {
      type: Boolean,
      default: false,
    },
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 450,
    },
    height: {
      type: Number,
      default: 350,
    },
    lastFocused: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 0,
    },
    app: {
      type: Object as PropType<VApp | null>,
      required: true,
    },
  },
  emits: ["focus", "close", "minimize", "resize"],
  setup(props, { emit }) {
    const { mobile } = useDisplay();
    const { viewport } = useViewport();
    const chat = ref<typeof MalarkeyChat | null>(null);
    const bus = inject<BusService>("bus");
    const sid = computed(() => props.sid);
    const x = computed(() => props.x);
    const y = computed(() => props.y);
    const width = computed(() => props.width);
    const height = computed(() => props.height);
    const minimized = computed(() => props.minimized);
    // const app = computed(() => props.app);
    const isGrabbing = ref(false);
    const isResizingTop = ref(false);
    const isResizingLeft = ref(false);
    const isResizingBottom = ref(false);
    const isResizingRight = ref(false);
    const isResizingTopLeft = ref(false);
    const isResizingTopRight = ref(false);
    const isResizingBottomLeft = ref(false);
    const isResizingBottomRight = ref(false);

    const mouseDownX = ref(0);
    const mouseDownY = ref(0);
    const changeInX = ref(0);
    const changeInY = ref(0);
    const changeInWidth = ref(0);
    const changeInHeight = ref(0);

    const wrapperTransform = computed(() => {
      return `translate(${changeInX.value}px, ${changeInY.value}px)`;
    });
    const wrapperWidth = computed(() => {
      return width.value + changeInWidth.value;
    });
    const wrapperHeight = computed(() => {
      return height.value + changeInHeight.value;
    });

    const zIndex = computed(() => {
      if (minimized.value) {
        return -9999;
      }
      const start = 2300;
      if (isGrabbing.value) {
        return start + 1000 + props.index;
      }
      return start + props.index;
    });
    const wrapperBindings = computed(() => ({
      class: [
        "m-iife-chat-window",
        isGrabbing.value ? "moving" : "",
        isResizingTop.value ? "resizing-top" : "",
        isResizingLeft.value ? "resizing-left" : "",
        isResizingBottom.value ? "resizing-bottom" : "",
        isResizingRight.value ? "resizing-right" : "",
        isResizingTopLeft.value ? "resizing-top-left" : "",
        isResizingTopRight.value ? "resizing-top-right" : "",
        isResizingBottomLeft.value ? "resizing-bottom-left" : "",
        isResizingBottomRight.value ? "resizing-bottom-right" : "",
      ].filter((v) => v.trim().length > 0),
      style: mobile.value
        ? {
            top: `${viewport.value.offsetTop}px`,
            left: `${viewport.value.offsetLeft}px`,
            bottom: 0,
            right: 0,
            zIndex: "10002 !important",
          }
        : {
            top: `${y.value}px`,
            left: `${x.value}px`,
            zIndex: `${zIndex.value} !important`,
            opacity: minimized.value ? 0 : 1,
            transform: wrapperTransform.value,
          },
      width: mobile.value ? viewport.value.width : wrapperWidth.value,
      height: mobile.value ? viewport.value.height : wrapperHeight.value,
      color: "surface",
      ripple: false,
      id: `m-iife-chat-${sid.value}`,
      elevation: 10,
    }));

    watch(
      () => viewport.value,
      (is) => {
        console.log(
          JSON.stringify({
            viewport: is,
            wrapperBindings: wrapperBindings.value,
          }),
        );
      },
      { deep: true, immediate: true },
    );

    const mIIFEBodyClasses = computed(() =>
      [
        isGrabbing.value ? "m-iife-moving" : "",
        isResizingTop.value ? "m-iife-resizing-top" : "",
        isResizingLeft.value ? "m-iife-resizing-left" : "",
        isResizingBottom.value ? "m-iife-resizing-bottom" : "",
        isResizingRight.value ? "m-iife-resizing-right" : "",
        isResizingTopLeft.value ? "m-iife-resizing-top-left" : "",
        isResizingTopRight.value ? "m-iife-resizing-top-right" : "",
        isResizingBottomLeft.value ? "m-iife-resizing-bottom-left" : "",
        isResizingBottomRight.value ? "m-iife-resizing-bottom-right" : "",
      ].filter((v) => v.trim().length > 0),
    );
    const chatWrapperBindings = computed(() => ({
      class: ["m-chat-wrapper"],
      style: {
        width: mobile.value ? `${viewport.value.width}px` : `${width.value}px`,
        height: mobile.value
          ? `${viewport.value.height - 31}px`
          : `${height.value - 31}px`,
      },
    }));
    watch(
      () => mIIFEBodyClasses.value,
      (is, was) => {
        if ("undefined" !== typeof document) {
          const htmlElem = document.documentElement;
          const bodyElem = document.body;
          if (bodyElem) {
            if (was) {
              htmlElem.classList.remove(...was);
              bodyElem.classList.remove(...was);
            }
            htmlElem.classList.add(...is);
            bodyElem.classList.add(...is);
          }
        }
      },
      { immediate: true, deep: true },
    );
    const doClose = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      emit("close", sid.value);
    };
    const doMinimize = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      emit("minimize", sid.value);
    };
    const doFocus = () => {
      if (isGrabbing.value) {
        return;
      }
      emit("focus", sid.value);
    };

    let resizeTimeout: NodeJS.Timeout | undefined;
    const doResize = (width: number, height: number, x: number, y: number) => {
      if (mobile.value) {
        // do not allow resize on mobile
        return;
      }
      emit("resize", sid.value, width, height, x, y);
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
      resizeTimeout = setTimeout(() => {
        resizeTimeout = undefined;
        if (chat.value) {
          debug("chat is available");
          chat.value.updateContainerAvailableHeight();
        } else {
          debug("chat is not available");
        }
        nextTick(() => {
          if (chat.value) {
            debug("chat is available");
            chat.value.updateContainerAvailableHeight();
          } else {
            debug("chat is not available");
          }
        });
      }, 350);
      nextTick(() => {
        if (chat.value) {
          debug("chat is available");
          chat.value.updateContainerAvailableHeight();
        } else {
          debug("chat is not available");
        }
      });
    };

    const setMouseDown = (e: MouseEvent) => {
      mouseDownX.value = e.clientX;
      mouseDownY.value = e.clientY;
    };

    const onMouseDownOnToolbar = (e: MouseEvent) => {
      // check to make sure that we're not trying to interact with one of the resize handles or press one of the buttons
      if (
        e.target instanceof HTMLElement &&
        (e.target.closest(".m-iife-chat-resize-handle") ||
          e.target.closest(".v-btn"))
      ) {
        return;
      }
      isGrabbing.value = true;
      setMouseDown(e);
    };
    const onMouseDownOnResizeTop = (e: MouseEvent) => {
      isResizingTop.value = true;
      setMouseDown(e);
    };
    const onMouseDownOnResizeLeft = (e: MouseEvent) => {
      isResizingLeft.value = true;
      setMouseDown(e);
    };
    const onMouseDownOnResizeBottom = (e: MouseEvent) => {
      isResizingBottom.value = true;
      setMouseDown(e);
    };
    const onMouseDownOnResizeRight = (e: MouseEvent) => {
      isResizingRight.value = true;
      setMouseDown(e);
    };
    const onMouseDownOnResizeTopLeft = (e: MouseEvent) => {
      isResizingTopLeft.value = true;
      setMouseDown(e);
    };
    const onMouseDownOnResizeTopRight = (e: MouseEvent) => {
      isResizingTopRight.value = true;
      setMouseDown(e);
    };
    const onMouseDownOnResizeBottomLeft = (e: MouseEvent) => {
      isResizingBottomLeft.value = true;
      setMouseDown(e);
    };
    const onMouseDownOnResizeBottomRight = (e: MouseEvent) => {
      isResizingBottomRight.value = true;
      setMouseDown(e);
    };
    const resetMoveOrResize = () => {
      mouseDownX.value = 0;
      mouseDownY.value = 0;
      changeInX.value = 0;
      changeInY.value = 0;
      changeInWidth.value = 0;
      changeInHeight.value = 0;
      if (isGrabbing.value) {
        nextTick(() => {
          isGrabbing.value = false;
        });
      }
      if (
        isResizingTop.value ||
        isResizingLeft.value ||
        isResizingBottom.value ||
        isResizingRight.value ||
        isResizingTopLeft.value ||
        isResizingTopRight.value ||
        isResizingBottomLeft.value ||
        isResizingBottomRight.value
      ) {
        nextTick(() => {
          isResizingTop.value = false;
          isResizingLeft.value = false;
          isResizingBottom.value = false;
          isResizingRight.value = false;
          isResizingTopLeft.value = false;
          isResizingTopRight.value = false;
          isResizingBottomLeft.value = false;
          isResizingBottomRight.value = false;
        });
      }
    };
    const onMouseMove = (e: MouseEvent) => {
      if (
        isGrabbing.value ||
        isResizingTop.value ||
        isResizingLeft.value ||
        isResizingBottom.value ||
        isResizingRight.value ||
        isResizingTopLeft.value ||
        isResizingTopRight.value ||
        isResizingBottomLeft.value ||
        isResizingBottomRight.value
      ) {
        debug("onMouseMove", e);
        e.preventDefault();
        e.stopPropagation();
        let changeInWidthToApply = 0;
        let changeInHeightToApply = 0;
        let changeInXToApply = 0;
        let changeInYToApply = 0;
        // for right resize
        if (
          isResizingRight.value ||
          isResizingTopRight.value ||
          isResizingBottomRight.value
        ) {
          // changeInWidth.value = e.clientX - mouseDownX.value;
          changeInWidthToApply = e.clientX - mouseDownX.value;
        }
        // for left resize
        if (
          isResizingLeft.value ||
          isResizingTopLeft.value ||
          isResizingBottomLeft.value
        ) {
          // changeInWidth.value = -1 * (e.clientX - mouseDownX.value);
          changeInWidthToApply = -1 * (e.clientX - mouseDownX.value);
        }
        // for bottom resize
        if (
          isResizingBottom.value ||
          isResizingBottomLeft.value ||
          isResizingBottomRight.value
        ) {
          // changeInHeight.value = e.clientY - mouseDownY.value;
          changeInHeightToApply = e.clientY - mouseDownY.value;
        }
        // for top resize
        if (
          isResizingTop.value ||
          isResizingTopLeft.value ||
          isResizingTopRight.value
        ) {
          // changeInHeight.value = -1 * (e.clientY - mouseDownY.value);
          changeInHeightToApply = -1 * (e.clientY - mouseDownY.value);
        }
        // Set changeInX for move or left resize
        if (
          isGrabbing.value ||
          isResizingLeft.value ||
          isResizingTopLeft.value ||
          isResizingBottomLeft.value
        ) {
          changeInXToApply = e.clientX - mouseDownX.value;
          if (
            isResizingLeft.value ||
            isResizingTopLeft.value ||
            isResizingBottomLeft.value
          ) {
            // If the new width after resizing is less than 300, adjust changeInXToApply to prevent shrink below minimum
            if (width.value + changeInWidthToApply < 300) {
              changeInXToApply += width.value + changeInWidthToApply - 300; // This reduces the change in X to maintain minimum width
            }
          }
        }

        // Set changeInY for move or top resize
        if (
          isGrabbing.value ||
          isResizingTop.value ||
          isResizingTopLeft.value ||
          isResizingTopRight.value
        ) {
          changeInYToApply = e.clientY - mouseDownY.value;
          if (
            isResizingTop.value ||
            isResizingTopLeft.value ||
            isResizingTopRight.value
          ) {
            // If the new height after resizing is less than 152, adjust changeInYToApply to prevent shrink below minimum
            if (height.value + changeInHeightToApply < 152) {
              changeInYToApply += height.value + changeInHeightToApply - 152; // This reduces the change in Y to maintain minimum height
            }
          }
        }
        if (changeInWidthToApply + width.value < 300) {
          // const difference = 300 + changeInWidthToApply;
          changeInWidthToApply = 300 - width.value;
          // changeInXToApply += difference;
        }
        if (changeInHeightToApply + height.value < 152) {
          // const difference = 152 + changeInHeightToApply;
          changeInHeightToApply = 152 - height.value;
          // changeInYToApply += difference;
        }
        /**
         * Here we need to prevent the window from being moved to the bottom or to the right
         * when resizing from the top or left and hitting the minimum width or height
         */
        changeInWidth.value = changeInWidthToApply;
        changeInHeight.value = changeInHeightToApply;
        changeInX.value = changeInXToApply;
        changeInY.value = changeInYToApply;
        return false;
      }
    };
    const onMouseUp = (e: MouseEvent) => {
      onMouseMove(e);
      const finalX = x.value + changeInX.value;
      const finalY = y.value + changeInY.value;
      const finalWidth = width.value + changeInWidth.value;
      const finalHeight = height.value + changeInHeight.value;
      if (isGrabbing.value) {
        /**
         * Commit
         */
        doResize(finalWidth, finalHeight, finalX, finalY);
        resetMoveOrResize();
        return false;
      }
      if (
        isResizingTop.value ||
        isResizingLeft.value ||
        isResizingBottom.value ||
        isResizingRight.value ||
        isResizingTopLeft.value ||
        isResizingTopRight.value ||
        isResizingBottomLeft.value ||
        isResizingBottomRight.value
      ) {
        doResize(finalWidth, finalHeight, finalX, finalY);
        resetMoveOrResize();
        return false;
      }
      resetMoveOrResize();
    };
    // const onMouseOut = (_e: MouseEvent) => {
    //   debug("onMouseOut");
    //   resetMoveOrResize();
    //   nextTick(() => {
    //     isGrabbing.value = false;
    //     isResizingTop.value = false;
    //     isResizingLeft.value = false;
    //     isResizingBottom.value = false;
    //     isResizingRight.value = false;
    //     isResizingTopLeft.value = false;
    //     isResizingTopRight.value = false;
    //     isResizingBottomLeft.value = false;
    //     isResizingBottomRight.value = false;
    //   });
    // };
    const onConversationsUpdated = () => {
      //   windowTransformX.value = 0;
      //   windowTransformY.value = 0;
    };
    onMounted(() => {
      if (bus) {
        // @ts-ignore - stupid typescript
        bus.on("m-chat-iife:conversation:updated", onConversationsUpdated, {
          local: true,
        });
        // // @ts-ignore - stupid typescript
        // bus.on("m-chat-iife:app:mousemove", onMouseMove, {
        //   local: true,
        // });
        // @ts-ignore - stupid typescript
        bus.on("m-chat-iife:app:mouseup", onMouseUp, {
          local: true,
        });
        // @ts-ignore - stupid typescript
        // bus.on("m-chat-iife:app:mouseleave", onMouseOut, {
        //   local: true,
        // });
      }
      if ("undefined" !== typeof window) {
        window.addEventListener("mouseup", onMouseUp, {
          capture: true,
        });
        window.addEventListener("mousemove", onMouseMove, {
          capture: true,
        });
      }
    });
    onBeforeUnmount(() => {
      if (bus) {
        // @ts-ignore - stupid typescript
        bus.off("m-chat-iife:conversation:updated", onConversationsUpdated, {
          local: true,
        });
        // // @ts-ignore - stupid typescript
        // bus.off("m-chat-iife:app:mousemove", onMouseMove, {
        //   local: true,
        // });
        // @ts-ignore - stupid typescript
        bus.off("m-chat-iife:app:mouseup", onMouseUp, {
          local: true,
        });
        // @ts-ignore - stupid typescript
        // bus.off("m-chat-iife:app:mouseleave", onMouseOut, {
        //   local: true,
        // });
      }
      if ("undefined" !== typeof window) {
        window.removeEventListener("mouseup", onMouseUp, {
          capture: true,
        });
        window.removeEventListener("mousemove", onMouseMove, {
          capture: true,
        });
      }
    });
    return {
      chat,
      wrapperBindings,
      chatWrapperBindings,
      doFocus,
      doClose,
      doMinimize,
      onMouseDownOnToolbar,
      onMouseDownOnResizeTop,
      onMouseDownOnResizeLeft,
      onMouseDownOnResizeBottom,
      onMouseDownOnResizeRight,
      onMouseDownOnResizeTopLeft,
      onMouseDownOnResizeTopRight,
      onMouseDownOnResizeBottomLeft,
      onMouseDownOnResizeBottomRight,
      onMouseUp,
    };
  },
});
</script>
