import { TinyEmitter } from "tiny-emitter";

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IIFEBusEventCallbackSignatures {
  "do:action-menu:open": () => void;
  "do:action-menu:close": () => void;
  "do:action-menu:toggle": () => void;
  "do:action-menu:show": () => void;
  "do:action-menu:hide": () => void;
  "do:authentication:login": (encryptedCustomerId: string) => void;
  "do:authentication:logout": () => void;
  "do:authentication:show": () => void;
}

export type IIFEBusEvent = keyof IIFEBusEventCallbackSignatures;

export type IIFEBusEventCallback<
  T extends keyof IIFEBusEventCallbackSignatures,
> = IIFEBusEventCallbackSignatures[T];

declare class IIFETinyEmitterWithEvents extends TinyEmitter {
  public e: Record<
    IIFEBusEvent,
    { ctx: unknown; fn: IIFEBusEventCallback<IIFEBusEvent> }[]
  >;
}

export class IIFEBus {
  #emitter: IIFETinyEmitterWithEvents;

  constructor() {
    this.#emitter = new TinyEmitter() as IIFETinyEmitterWithEvents;
  }

  public on<T extends IIFEBusEvent>(
    event: T,
    callback: IIFEBusEventCallback<T>,
    ctx?: unknown,
  ) {
    this.#emitter.on(event, callback, ctx);
  }

  public once<T extends IIFEBusEvent>(
    event: T,
    callback: IIFEBusEventCallback<T>,
    ctx?: unknown,
  ) {
    this.#emitter.once(event, callback, ctx);
  }

  public off<T extends IIFEBusEvent>(
    event: T,
    callback?: IIFEBusEventCallback<T>,
  ) {
    this.#emitter.off(event, callback);
  }

  public emit<T extends IIFEBusEvent>(
    event: T,
    ...args: Parameters<IIFEBusEventCallback<T>>
  ) {
    this.#emitter.emit(event, ...args);
    this.#emitToWindow(event, ...args);
  }

  #emitToWindow<T extends IIFEBusEvent>(
    event: T,
    ...args: Parameters<IIFEBusEventCallback<T>>
  ) {
    if (typeof window !== "undefined") {
      window.dispatchEvent(
        new CustomEvent(`m-chat-iife-bus`, {
          detail: {
            event,
            args,
            fromBus: true,
          },
        }),
      );
    }
  }

  #onEventFromWindow = (event: Event) => {
    if ((event as CustomEvent).detail) {
      if (
        (event as CustomEvent).detail.event &&
        (event as CustomEvent).detail.fromBus !== true
      ) {
        this.emit(
          (event as CustomEvent).detail.event,
          ...((event as CustomEvent).detail.args ?? []),
        );
      }
    }
  };

  public install(window?: globalThis.Window | undefined) {
    if (window) {
      window.addEventListener(`m-chat-iife-bus`, this.#onEventFromWindow);
    }
  }

  public uninstall(window?: globalThis.Window | undefined) {
    if (window) {
      window.removeEventListener(`m-chat-iife-bus`, this.#onEventFromWindow);
    }
  }
}

export const postMessageToIIFE = (event: IIFEBusEvent, ...args: unknown[]) => {
  if (typeof window !== "undefined") {
    window.dispatchEvent(
      new CustomEvent(`m-chat-iife-bus`, {
        detail: {
          event,
          args,
        },
      }),
    );
  }
};
