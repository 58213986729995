<template>
  <div>
    <v-hover>
      <template #default="{ isHovering, props }">
        <!-- @vue-ignore -->
        <div v-bind="{ ...wrapperBindings, ...props }" @click="doFocus">
          <v-badge v-bind="vBadgeBindings">
            <v-fab
              v-bind="chatFabBindings"
              @mousedown="onMouseDown"
              @touchstart="onTouchStart"
            >
              <component
                :is="avatarComponent"
                v-bind="avatarComponentBindings"
              />
            </v-fab>
          </v-badge>
          <v-fade-transition leave-absolute>
            <v-card
              v-if="
                lastMessagePreview && (showLastMessagePreview || isHovering)
              "
              v-bind="messagePreviewBindings"
              @click="doMaximize"
            >
              <div class="last-message-preview-body px-3 pt-2 text-truncate">
                {{ lastMessagePreview }}
              </div>
            </v-card>
          </v-fade-transition>
        </div>
      </template>
    </v-hover>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  ref,
  inject,
  onMounted,
  onBeforeUnmount,
  watch,
  shallowRef,
  triggerRef,
} from "vue";
import { VAvatar } from "vuetify/components/VAvatar";
import {
  stringOr,
  fromMarkdownLike,
} from "@nhtio/malarkey-client-core/utilities/helpers";
import { getDebugger } from "@jakguru/vueprint/utilities/debug";
import MalarkeyContactAvatar from "@nhtio/malarkey-client-core/components/constituants/MalarkeyContactAvatar";
import { DateTime } from "luxon";
import { useDisplay } from "vuetify";

import type { Ref } from "vue";
import type {
  Conversation,
  ConversationUpdateReason,
  Participant,
  LastMessage,
  Message,
} from "@twilio/conversations";
import type {
  IdentityService,
  BusService,
  CronService,
} from "@jakguru/vueprint";
import type {
  MalarkeyTwilioAdapter,
  ExtendedRosterItem,
  ConversationWithChecksum,
} from "@nhtio/malarkey-client-core/twilio/malarkeyTwilioAdapter";
import { nextTick } from "vue";

const makePreview = (contents: string | undefined) => {
  if (!contents) {
    return "";
  }
  const fromMarkdown = fromMarkdownLike(contents);
  const tempDivElement = document.createElement("div");
  tempDivElement.innerHTML = fromMarkdown;
  return tempDivElement.textContent || tempDivElement.innerText || "";
};

const debug = getDebugger("Chat Head", "#E34C26", "#FFFFFF");

export default defineComponent({
  name: "ChatHead",
  components: {
    VAvatar,
    MalarkeyContactAvatar,
  },
  props: {
    sid: {
      type: String,
      required: true,
    },
    minimized: {
      type: Boolean,
      default: false,
    },
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 450,
    },
    height: {
      type: Number,
      default: 350,
    },
    index: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 56,
    },
    timeout: {
      type: Number,
      default: 5,
    },
    lastFocused: {
      type: String,
      default: "",
    },
  },
  emits: ["focus", "close", "maximize", "resize", "move"],
  setup(props, { emit }) {
    const mounted = ref(false);
    onMounted(() => {
      mounted.value = true;
    });
    onBeforeUnmount(() => {
      mounted.value = false;
    });
    const { width: displayWidth, mobile } = useDisplay();
    const sid = computed(() => props.sid);
    const size = computed(() => props.size);
    const hidePreviewTimeout = computed(() => props.timeout);
    const x = computed(() => props.x);
    const y = computed(() => props.y);
    const width = computed(() => (mobile.value ? size.value : props.width));
    const height = computed(() => (mobile.value ? size.value : props.height));
    const xToUse = computed(() => x.value + (width.value - size.value) / 2);
    const yToUse = computed(() => y.value + (height.value - size.value) / 2);
    const minimized = computed(() => props.minimized);
    const zIndex = computed(() => {
      if (!minimized.value) {
        return -9999;
      }
      const start = 2300;
      if (isGrabbing.value) {
        return start + 1000 + props.index;
      }
      return start + props.index;
    });

    const mouseDownX = ref(0);
    const mouseDownY = ref(0);
    const changeInX = ref(0);
    const changeInY = ref(0);
    const isMouseDown = ref(false);
    const isGrabbing = computed(
      () =>
        isMouseDown.value && (changeInX.value !== 0 || changeInY.value !== 0),
    );

    const dbgd = computed(() => ({
      mouseDownX: mouseDownX.value,
      mouseDownY: mouseDownY.value,
      changeInX: changeInX.value,
      changeInY: changeInY.value,
      isMouseDown: isMouseDown.value,
    }));

    const transform = computed(() => {
      return `translate(${changeInX.value}px, ${changeInY.value}px)`;
    });

    const wrapperBindings = computed(() => ({
      class: ["m-iife-chat-head", isGrabbing.value ? "moving" : ""].filter(
        (v) => v.trim().length > 0,
      ),
      style: {
        top: `${yToUse.value}px`,
        left: `${xToUse.value}px`,
        zIndex: `${zIndex.value} !important`,
        opacity: minimized.value ? 1 : 0,
        borderRadius: "50%",
        transform: transform.value,
        width: `${size.value}px`,
        height: `${size.value}px`,
      },
      id: `m-iife-chat-${sid.value}`,
      draggable: "false",
    }));

    const mIIFEBodyClasses = computed(() =>
      [isGrabbing.value ? "m-iife-moving" : ""].filter(
        (v) => v.trim().length > 0,
      ),
    );
    watch(
      () => mIIFEBodyClasses.value,
      (is, was) => {
        if ("undefined" !== typeof document) {
          const htmlElem = document.documentElement;
          const bodyElem = document.body;
          if (bodyElem) {
            if (was) {
              htmlElem.classList.remove(...was);
              bodyElem.classList.remove(...was);
            }
            htmlElem.classList.add(...is);
            bodyElem.classList.add(...is);
          }
        }
      },
      { immediate: true, deep: true },
    );

    const doMaximize = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      emit("maximize", sid.value);
    };

    const doFocus = () => {
      emit("focus", sid.value);
    };
    const doMove = (x: number, y: number) => {
      emit("move", sid.value, x, y);
    };

    const identity = inject<IdentityService>("identity");
    const bus = inject<BusService>("bus");
    const cron = inject<CronService>("cron");
    const twilio = inject<MalarkeyTwilioAdapter>("twilio");
    const now = shallowRef<DateTime>(DateTime.now());
    const onSecond = () => {
      now.value = DateTime.now();
      triggerRef(now);
    };
    const authenticated = computed(() => {
      if (!mounted.value || !identity || !identity.booted.value) {
        return null;
      }
      if (!identity.authenticated.value) {
        return false;
      }
      return identity.authenticated.value;
    });
    const unreadMessageCount = ref<number>(0);
    const hasUnreadMessages = computed(() => unreadMessageCount.value > 0);
    // @ts-ignore
    const participants: Ref<Participant[]> = ref<Participant[]>(
      [] as Participant[],
    ) as Ref<Participant[]>;
    const lastMessage = ref<LastMessage | undefined>(undefined) as Ref<
      LastMessage | undefined
    >;
    const lastMessageContents = ref<string | undefined>(undefined);
    const lastMessageSender = ref<string | undefined>(undefined);
    const conversationItem = shallowRef<ConversationWithChecksum | undefined>(
      undefined,
    );
    const loadConversationItem = () => {
      debug("Loading Conversation Item", sid.value);
      if (!twilio || !twilio.conversations.value) {
        conversationItem.value = undefined;
        triggerRef(conversationItem);
        nextTick(() => {
          handleConversationUpdate(conversationItem.value);
        });
        return;
      }
      conversationItem.value = twilio.conversations.value.find(
        (c) => c.sid === sid.value,
      );
      triggerRef(conversationItem);
      nextTick(() => {
        handleConversationUpdate(conversationItem.value);
      });
      return;
    };
    const onTwilioConversationUpdated = ({
      conversation,
    }: {
      conversation: Conversation;
      updateReasons: ConversationUpdateReason[];
    }) => {
      if (conversation.sid === sid.value) {
        loadConversationItem();
      }
    };
    const onTwilioConversationChanged = (conversation: Conversation) => {
      if (conversation.sid === sid.value) {
        loadConversationItem();
      }
    };
    const onTwilioMessageAddedOrRemoved = (message: Message) => {
      if (message.conversation.sid === sid.value) {
        handleConversationUpdate(message.conversation);
      }
    };
    const onTwilioMessageAdded = (message: Message) => {
      if (message.conversation.sid === sid.value) {
        lastMessageEventAt.value = DateTime.now();
        triggerRef(lastMessageEventAt);
      }
    };
    const onTwilioMessageEdited = ({ message }: { message: Message }) => {
      if (message.conversation.sid === sid.value) {
        handleConversationUpdate(message.conversation);
      }
    };
    // const conversationItem = computed(() => {
    //   if (!twilio || !twilio.conversations.value) {
    //     return null;
    //   }
    //   return twilio.conversations.value.find((c) => c.sid === sid.value);
    // });
    const isGroupChat = computed(() => participants.value.length > 2);
    const nonUserParticipants = computed(() =>
      // @ts-ignore
      [...participants.value].filter(
        (p) => authenticated.value && p.identity !== identity!.user.value!.id,
      ),
    );
    const participantLastReadMessageIndecies = ref<Record<string, number>>({});
    const avatarComponent = computed(() =>
      isGroupChat.value ? "VAvatar" : "MalarkeyContactAvatar",
    );
    const avatarComponentParticipant = computed(() =>
      isGroupChat.value ? null : nonUserParticipants.value[0],
    );
    const avatarComponentBindings = computed(() => {
      const basics = {};
      if (isGroupChat.value) {
        return {
          ...basics,
          icon: "mdi-account-group",
          size: 40,
        };
      } else {
        return {
          ...basics,
          size: size.value - 2,
          identity:
            "undefined" !== typeof avatarComponentParticipant.value &&
            null !== avatarComponentParticipant.value
              ? avatarComponentParticipant.value.identity
              : undefined,
        };
      }
    });
    const lastMessagePreview = computed(() => {
      let ret = "";
      if (
        isGroupChat.value &&
        lastMessageSender.value &&
        lastMessageContents.value &&
        twilio
      ) {
        const rosterItem = twilio.roster.value.find(
          (r) =>
            "undefined" !== typeof r &&
            r.identifier === lastMessageSender.value,
        ) as ExtendedRosterItem | undefined;
        if (rosterItem) {
          const name = stringOr(
            rosterItem.name || undefined,
            stringOr(
              rosterItem.friendlyName || undefined,
              stringOr(rosterItem.identifier || undefined),
            ),
          );
          if (name) {
            ret = `${name}: `;
          }
        }
      }
      if (lastMessageContents.value) {
        ret += makePreview(lastMessageContents.value);
      }
      return ret;
    });
    const lastMessageEventAt = shallowRef<DateTime | undefined>(undefined);
    const lastMessageAt = computed(() => {
      if (
        lastMessage.value &&
        lastMessage.value.dateCreated &&
        lastMessageEventAt.value
      ) {
        const v1 = DateTime.fromJSDate(lastMessage.value.dateCreated);
        const v2 = lastMessageEventAt.value;
        return v1 > v2 ? v1 : v2;
      } else if (lastMessage.value && lastMessage.value.dateCreated) {
        return DateTime.fromJSDate(lastMessage.value.dateCreated);
      } else if (lastMessageEventAt.value) {
        return lastMessageEventAt.value;
      }
      return undefined;
    });
    // if the last message was sent within the last {timeout} seconds, return true otherwise false
    const showLastMessagePreview = computed(() => {
      if (!lastMessageAt.value || !now.value) {
        return false;
      }
      return (
        now.value.diff(lastMessageAt.value, "seconds").seconds <
        hidePreviewTimeout.value
      );
    });
    const handleConversationUpdate = (i?: Conversation | undefined) => {
      debug("Conversation Item Changed", i);
      if (i) {
        lastMessage.value = i.lastMessage;
        i.getParticipants()
          .then((list) => {
            if (
              conversationItem.value &&
              i.sid === conversationItem.value.sid
            ) {
              // @ts-ignore
              participants.value = list;
              participantLastReadMessageIndecies.value = {};
              list.forEach((p) => {
                const lri = p.lastReadMessageIndex;
                if ("number" === typeof lri) {
                  participantLastReadMessageIndecies.value[p.identity!] = lri;
                } else {
                  participantLastReadMessageIndecies.value[p.identity!] = 0;
                }
              });
            }
          })
          .catch(() => {
            if (
              conversationItem.value &&
              i.sid === conversationItem.value.sid
            ) {
              participants.value = [];
              participantLastReadMessageIndecies.value = {};
            }
          });
        i.getUnreadMessagesCount()
          .then((count) => {
            if (
              conversationItem.value &&
              i.sid === conversationItem.value.sid
            ) {
              if ("number" === typeof count) {
                unreadMessageCount.value = count;
              } else {
                unreadMessageCount.value = 0;
              }
            }
          })
          .catch(() => {
            if (
              conversationItem.value &&
              i.sid === conversationItem.value.sid
            ) {
              unreadMessageCount.value = 0;
            }
          });
        i.getMessages(1)
          .then((paginator) => {
            const message = paginator.items[0];
            if (message) {
              if ("string" === typeof message.body) {
                lastMessageContents.value = message.body;
              } else {
                lastMessageContents.value = undefined;
              }
              if (message.participantSid) {
                lastMessageSender.value = message.participantSid;
              } else {
                lastMessageSender.value = undefined;
              }
            } else {
              lastMessageContents.value = undefined;
              lastMessageSender.value = undefined;
            }
          })
          .catch(() => {
            lastMessageContents.value = undefined;
            lastMessageSender.value = undefined;
          });
      } else {
        lastMessage.value = undefined;
        participants.value = [];
        unreadMessageCount.value = 0;
        participantLastReadMessageIndecies.value = {};
        lastMessageContents.value = undefined;
        lastMessageSender.value = undefined;
      }
    };
    watch(() => conversationItem.value, handleConversationUpdate, {
      immediate: true,
    });

    const onMouseDown = (e: MouseEvent) => {
      if (e.button !== 0) {
        return;
      }
      debug("Mouse Down", e);
      isMouseDown.value = true;
      mouseDownX.value = e.clientX;
      mouseDownY.value = e.clientY;
    };

    const onTouchStart = (e: TouchEvent) => {
      if (e.touches.length !== 1) {
        return;
      }
      debug("Touch Start", e);
      isMouseDown.value = true;
      mouseDownX.value = e.touches[0].clientX;
      mouseDownY.value = e.touches[0].clientY;
    };

    const onMouseMove = (e: MouseEvent) => {
      if (!isMouseDown.value) {
        return;
      }
      debug("Mouse Move", e);
      changeInX.value = e.clientX - mouseDownX.value;
      changeInY.value = e.clientY - mouseDownY.value;
      debug("Mouse Moved", dbgd.value);
    };

    const onLongTouch = (e: CustomEvent) => {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
    };

    const onTouchMove = (e: TouchEvent) => {
      if (!isMouseDown.value || e.touches.length !== 1) {
        return;
      }
      // @ts-ignore
      window.addEventListener("longtouch", onLongTouch, {
        capture: true,
        once: true,
      });
      e.preventDefault();
      e.stopPropagation();
      debug("Touch Move", e);
      changeInX.value = e.touches[0].clientX - mouseDownX.value;
      changeInY.value = e.touches[0].clientY - mouseDownY.value;
      debug("Touch Moved", dbgd.value);
    };

    const onMouseUp = (e: MouseEvent) => {
      if (!isMouseDown.value || !isMouseDown.value) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      changeInX.value = e.clientX - mouseDownX.value;
      changeInY.value = e.clientY - mouseDownY.value;
      debug("Mouse Up", dbgd.value);
      isMouseDown.value = false;
      if (changeInX.value !== 0 || changeInY.value !== 0) {
        doMove(x.value + changeInX.value, y.value + changeInY.value);
        nextTick(() => {
          changeInX.value = 0;
          changeInY.value = 0;
        });
      } else {
        doMaximize(e);
      }
    };

    const onTouchEnd = (e: TouchEvent) => {
      if (!isMouseDown.value) {
        return;
      }
      // @ts-ignore
      window.addEventListener("longtouch", onLongTouch, {
        capture: true,
        once: true,
      });
      changeInX.value = e.changedTouches[0].clientX - mouseDownX.value;
      changeInY.value = e.changedTouches[0].clientY - mouseDownY.value;
      debug("Touch End", dbgd.value);
      isMouseDown.value = false;
      if (changeInX.value !== 0 || changeInY.value !== 0) {
        doMove(x.value + changeInX.value, y.value + changeInY.value);
        nextTick(() => {
          changeInX.value = 0;
          changeInY.value = 0;
        });
      } else {
        doMaximize(e);
      }
    };

    const chatFabBindings = computed(() => ({
      width: size.value,
      height: size.value,
      color: "surface",
      icon: true,
      elevation: 13,
      draggable: "false",
      rounded: "circle",
    }));

    const vBadgeBindings = computed(() => ({
      color: "notify",
      content: unreadMessageCount.value,
      floating: true,
      modelValue: hasUnreadMessages.value,
      draggable: "false",
      offsetX: 10,
      offsetY: 10,
    }));

    const realXOfChatHead = computed(() => xToUse.value + changeInX.value);
    const centerXOfChatHead = computed(
      () => realXOfChatHead.value + size.value / 2,
    );
    const centerXOfDisplay = computed(() => displayWidth.value / 2);
    const hemisphere = computed(() =>
      centerXOfChatHead.value < centerXOfDisplay.value ? "right" : "left",
    );
    const anchor = computed(() =>
      centerXOfChatHead.value < centerXOfDisplay.value ? "left" : "right",
    );

    const messagePreviewBindings = computed(() => ({
      class: [
        "m-iife-chat-head-message-preview",
        `m-iife-chat-head-message-preview-${hemisphere.value}`,
      ].filter((v) => v.trim().length > 0),
      draggable: "false",
      style: {
        top: `calc(${size.value / 2}px - 15px)`,
        [anchor.value]: `calc(${size.value}px + 9px)`,
      },
      height: 30,
      color: "primary",
      elevation: 13,
    }));

    onMounted(() => {
      loadConversationItem();
      if (bus) {
        // @ts-ignore - stupid typescript
        bus.on("m-chat-iife:app:mouseup", onMouseUp, {
          local: true,
        });
        // @ts-ignore - stupid typescript
        bus.on("m-chat-iife:app:touchend", onTouchEnd, {
          local: true,
        });
        bus.on(
          // @ts-ignore - stupid typescript
          "twilio:client:conversationUpdated",
          onTwilioConversationUpdated,
          {
            local: true,
          },
        );
        // @ts-ignore - stupid typescript
        bus.on("twilio:client:conversationAdded", onTwilioConversationChanged, {
          local: true,
        });
        bus.on(
          // @ts-ignore - stupid typescript
          "twilio:client:conversationJoined",
          onTwilioConversationChanged,
          {
            local: true,
          },
        );
        // @ts-ignore - stupid typescript
        bus.on("twilio:client:conversationLeft", onTwilioConversationChanged, {
          local: true,
        });
        // @ts-ignore - stupid typescript
        bus.on("twilio:client:messageAdded", onTwilioMessageAddedOrRemoved, {
          local: true,
        });
        // @ts-ignore - stupid typescript
        bus.on("twilio:client:messageAdded", onTwilioMessageAdded, {
          local: true,
        });
        // @ts-ignore - stupid typescript
        bus.on("twilio:client:messageRemoved", onTwilioMessageAddedOrRemoved, {
          local: true,
        });
        // @ts-ignore - stupid typescript
        bus.on("twilio:client:messageUpdated", onTwilioMessageEdited, {
          local: true,
        });
        // @ts-ignore - stupid typescript
        bus.on("twilio:adapter:conversations:updated", loadConversationItem, {
          local: true,
        });
      }
      if ("undefined" !== typeof window) {
        window.addEventListener("mouseup", onMouseUp, {
          capture: true,
        });
        window.addEventListener("drop", onMouseUp, {
          capture: true,
        });
        window.addEventListener("mousemove", onMouseMove, {
          capture: true,
        });
        window.addEventListener("touchmove", onTouchMove, {
          capture: true,
          passive: false,
        });
        window.addEventListener("touchend", onTouchEnd, {
          capture: true,
          passive: false,
        });
      }
      if (cron) {
        cron.$on("* * * * * *", onSecond);
      }
    });
    onBeforeUnmount(() => {
      if (bus) {
        // @ts-ignore - stupid typescript
        bus.off("m-chat-iife:app:mouseup", onMouseUp, {
          local: true,
        });
        // @ts-ignore - stupid typescript
        bus.off("m-chat-iife:app:touchend", onTouchEnd, {
          local: true,
        });
        bus.off(
          // @ts-ignore - stupid typescript
          "twilio:client:conversationUpdated",
          onTwilioConversationUpdated,
          {
            local: true,
          },
        );
        bus.off(
          // @ts-ignore - stupid typescript
          "twilio:client:conversationAdded",
          onTwilioConversationChanged,
          {
            local: true,
          },
        );
        bus.off(
          // @ts-ignore - stupid typescript
          "twilio:client:conversationJoined",
          onTwilioConversationChanged,
          {
            local: true,
          },
        );
        // @ts-ignore - stupid typescript
        bus.off("twilio:client:conversationLeft", onTwilioConversationChanged, {
          local: true,
        });
        // @ts-ignore - stupid typescript
        bus.off("twilio:client:messageAdded", onTwilioMessageAddedOrRemoved, {
          local: true,
        });
        // @ts-ignore - stupid typescript
        bus.off("twilio:client:messageAdded", onTwilioMessageAdded, {
          local: true,
        });
        // @ts-ignore - stupid typescript
        bus.off("twilio:client:messageRemoved", onTwilioMessageAddedOrRemoved, {
          local: true,
        });
        // @ts-ignore - stupid typescript
        bus.off("twilio:client:messageUpdated", onTwilioMessageEdited, {
          local: true,
        });
        // @ts-ignore - stupid typescript
        bus.off("twilio:adapter:conversations:updated", loadConversationItem, {
          local: true,
        });
      }
      if ("undefined" !== typeof window) {
        window.removeEventListener("mouseup", onMouseUp, {
          capture: true,
        });
        window.removeEventListener("drop", onMouseUp, {
          capture: true,
        });
        window.removeEventListener("mousemove", onMouseMove, {
          capture: true,
        });
        window.removeEventListener("touchmove", onTouchMove, {
          capture: true,
        });
        window.removeEventListener("touchend", onTouchEnd, {
          capture: true,
        });
      }
      if (cron) {
        cron.$off("* * * * * *", onSecond);
      }
    });
    return {
      wrapperBindings,
      doFocus,
      doMaximize,
      onMouseDown,
      onTouchStart,
      chatFabBindings,
      avatarComponent,
      avatarComponentBindings,
      vBadgeBindings,
      showLastMessagePreview,
      lastMessagePreview,
      messagePreviewBindings,
    };
  },
});
</script>
