import { createApp, ref, computed } from "vue";
import "./m-chat-iife.scss";
import MChatIife from "./m-chat-iife.vue";
import { getDebugger } from "@jakguru/vueprint/utilities/debug";
import { getVuePrintConfigurationFromCustomizations } from "@nhtio/malarkey-client-core/utilities/configuration";
import { MalarkeySSRPlugin } from "@nhtio/malarkey-client-core/utilities/ssr";
import { MalarkeyTwilioAdapter } from "@nhtio/malarkey-client-core/twilio/malarkeyTwilioAdapter";
import * as MChat from "./m-chat-iife-types";
import * as MChatValidators from "./m-chat-iife-validators";
import * as VuetifyComponents from "vuetify/components";
import * as VuetifyDirectives from "vuetify/directives";
import * as MalarkeyComponents from "@nhtio/malarkey-client-core";
import VueMainBootstrap from "@jakguru/vueprint/plugins/main";
import VueClientBootstrap from "@jakguru/vueprint/plugins/client";
import { MChatIIFEConversations } from "./m-chat-iife-conversations";
import { IIFEBus, postMessageToIIFE } from "./m-chat-iife-bus";

import type { MalarkeyClientCoreOptions } from "@nhtio/malarkey-client-core";
import type { App, Ref } from "vue";

const debug = getDebugger("IIFE", "#FFFFFF", "#E34C26");

const onFatal = (error: Error) => {
  debug(error);
  throw new Error("MChatIIFE: Fatal error");
};

const M_CHAT_IIFE_APP_ID = "m-chat-iife-app";

export class MChatIIFE {
  // @ts-expect-error - This is actually set in the constructor, but TypeScript doesn't see that for some reason
  readonly #options: Ref<MChat.MChatIIFEConfigOptions>;
  // @ts-expect-error - This is actually set in the constructor, but TypeScript doesn't see that for some reason
  readonly #app: App;
  // @ts-expect-error - This is actually set in the constructor, but TypeScript doesn't see that for some reason
  readonly #bus: IIFEBus;

  private constructor(
    database: MChatIIFEConversations,
    options: Partial<MChat.MChatIIFEConfigOptions> = {},
  ) {
    /**
     * Validate that the options are valid and that we are in an environment
     * which is able to load the script.
     */
    if (typeof window === "undefined" || typeof document === "undefined") {
      const err = new Error(
        "MChatIIFE can only be used in a browser environment",
      );
      onFatal(err);
    }
    const { error, value } =
      MChatValidators.mChatIIFEConfigOptionsSchema.validate(options);
    if (error) {
      return onFatal(error);
    }
    if (!("process" in window)) {
      window.process = {} as any;
    }
    if (!("nextTick" in window.process)) {
      (window.process as any).nextTick = (
        fn: (...args: unknown[]) => void,
        ...args: unknown[]
      ) => setTimeout(() => fn(...args));
    }
    this.#options = ref(value);
    this.#bus = new IIFEBus();
    /**
     * Show an alert if options.allowLoginWithoutAuth is true
     */
    if (true === this.#options.value.allowLoginWithoutAuth) {
      debug(
        "Login without explicity authorization is enabled. For a better experience, please use a seamless login option.",
      );
    }
    /**
     * Ensure that the selector exists and that we haven't already added the
     * application to the DOM.
     */
    const element = document.querySelector(this.#options.value.selector);
    if (!element) {
      const err = new Error(
        `MChatIIFE: Could not find element with selector ${this.#options.value.selector}`,
      );
      return onFatal(err);
    }
    const existingApp = element.querySelector(`#${M_CHAT_IIFE_APP_ID}`);
    if (existingApp) {
      const err = new Error(
        `MChatIIFE: App already exists in element with selector ${this.#options.value.selector}`,
      );
      return onFatal(err);
    }
    /**
     * Create the new app container and append it to the DOM as a child under the selector's element.
     */
    const appContainer = document.createElement("div");
    appContainer.id = M_CHAT_IIFE_APP_ID;
    element.appendChild(appContainer);
    /**
     * Initialize the Vue Application
     */
    const allowLoginWithoutAuth = computed(
      () => this.#options.value.allowLoginWithoutAuth,
    );
    const actionMenuStyling = computed(
      () => this.#options.value.actionMenuStyling,
    );
    const actionMenuCardBindings = computed(
      () => this.#options.value.actionMenuCardBindings,
    );
    const shouldDebug = computed(
      () => this.#options.value.debug && import.meta.env.DEV,
    );
    const chatHeadSize = computed(() => this.#options.value.chatHeadSize);
    const showByDefault = computed(() => this.#options.value.showByDefault);
    this.#app = createApp(MChatIife, {
      // allowLoginWithoutAuth: allowLoginWithoutAuth.value,
      allowLoginWithoutAuth,
      // actionMenuStyling: actionMenuStyling.value,
      actionMenuStyling,
      // actionMenuCardBindings: actionMenuCardBindings.value,
      actionMenuCardBindings,
      // chatHeadSize: chatHeadSize.value,
      chatHeadSize,
      // showByDefault: showByDefault.value,
      showByDefault,
      customizations: import.meta.env.customizations,
      audioFiles: import.meta.env.audioFiles,
      visualFiles: import.meta.env.visualFiles,
      // debug: shouldDebug.value,
      debug: shouldDebug,
      database,
      bus: this.#bus,
    });
    const { main, client } = getVuePrintConfigurationFromCustomizations(
      import.meta.env.customizations,
      import.meta.env.audioFiles,
      // import.meta.env.MODE === "production" ? "classic" : "module",
      // import.meta.env.MODE === "production"
      // ? this.#options.value.serviceWorkerPath
      // : "/dev-sw.js?dev-sw",
      null,
      null,
      false,
    );
    main.vuetify!.options = {
      ...main.vuetify!.options,
      components: VuetifyComponents,
      directives: VuetifyDirectives,
    };
    this.#app.use(VueMainBootstrap, main);
    this.#app.use(VueClientBootstrap, client);
    this.#app.use(MalarkeySSRPlugin, {
      psk: import.meta.env.customizations.api.psk,
    } as MalarkeyClientCoreOptions);
    this.#app.use(MalarkeyComponents, {
      psk: import.meta.env.customizations.api.psk,
    } as MalarkeyClientCoreOptions);
    this.#app.use(MalarkeyTwilioAdapter, { ssr: false });
    /**
     * Mount the application to the app container
     */
    this.#app.mount(appContainer);
  }

  public get actionMenu() {
    const open = () => {
      this.#bus.emit("do:action-menu:open");
    };
    const close = () => {
      this.#bus.emit("do:action-menu:close");
    };
    const toggle = () => {
      this.#bus.emit("do:action-menu:toggle");
    };
    const show = () => {
      this.#bus.emit("do:action-menu:show");
    };
    const hide = () => {
      this.#bus.emit("do:action-menu:hide");
    };
    const ret = {
      open,
      close,
      toggle,
      show,
      hide,
    };
    Object.freeze(ret);
    return ret;
  }

  public get authentication() {
    const login = (encryptedCustomerId: string) => {
      this.#bus.emit("do:authentication:login", encryptedCustomerId);
    };
    const logout = () => {
      this.#bus.emit("do:authentication:logout");
    };
    const show = () => {
      this.#bus.emit("do:authentication:show");
    };
    const ret = {
      login,
      logout,
      show,
    };
    Object.freeze(ret);
    return ret;
  }

  public get configuration() {
    const get = (key: keyof MChat.MChatIIFEConfigOptions) => {
      return this.#options.value[key];
    };
    const set = <K extends keyof MChat.MChatIIFEConfigOptions>(
      key: K,
      value: MChat.MChatIIFEConfigOptions[K],
    ) => {
      switch (key) {
        case "selector":
        case "serviceWorkerPath":
        case "showByDefault":
          debug(`Cannot set ${key} after initialization`);
          return;
        default:
          if (
            !(
              (key as keyof typeof MChatValidators.mChatIIFEConfigOptionsSchemaValidators) in
              MChatValidators.mChatIIFEConfigOptionsSchemaValidators
            )
          ) {
            debug(`Cannot set ${key} as it is not a valid option`);
            return;
          }
      }
      const { value: validated, error } =
        MChatValidators.mChatIIFEConfigOptionsSchemaValidators[
          key as keyof typeof MChatValidators.mChatIIFEConfigOptionsSchemaValidators
        ].validate(value);
      if (error) {
        debug(`Error setting ${key}: ${error.message}`);
        return;
      }
      debug(`Setting ${key} to:`, validated);
      this.#options.value[key] = validated;
    };
    const obj = {
      get,
      set,
    };
    Object.freeze(obj);
    return obj;
  }

  public destroy() {
    this.#app.unmount();
    window._MChatIIFE = undefined;
    delete window._MChatIIFE;
  }

  public static async init(
    options: Partial<MChat.MChatIIFEConfigOptions> = {},
  ) {
    if (typeof window === "undefined" || typeof document === "undefined") {
      const err = new Error(
        "MChatIIFE can only be used in a browser environment",
      );
      return onFatal(err);
    }
    if ("_MChatIIFE" in window && window._MChatIIFE instanceof MChatIIFE) {
      return window._MChatIIFE;
    }
    const conversationsDbInstance = await MChatIIFEConversations.init(
      import.meta.env.customizations.namespace,
      import.meta.env.customizations.api.psk,
    );
    const instance = new MChatIIFE(conversationsDbInstance, options);
    window._MChatIIFE = instance;
    return instance;
  }
}

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    MChatIIFE?: typeof MChatIIFE;
    _MChatIIFE?: MChatIIFE;
    env: Record<string, string>;
  }
}

if (typeof window !== "undefined") {
  window.MChatIIFE = MChatIIFE;
  window.postMessageToIIFE = postMessageToIIFE;
  const MChatIIFELoadedEvent = new CustomEvent("MChatIIFE:loaded", {
    detail: { MChatIIFE },
  });
  window.dispatchEvent(MChatIIFELoadedEvent);
}
