import Dexie from "dexie";
import { getDebugger } from "@jakguru/vueprint/utilities/debug";
import { DateTime } from "luxon";

import type { BusService } from "@jakguru/vueprint";
import type { EntityTable } from "dexie";
const debug = getDebugger("DB", "#E34C26", "#FFFFFF");

export interface MChatIIFEConversation {
  sid: string;
  minimized: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
  lastFocused: string;
}

type MChatIIFEConversationsDBType = Dexie & {
  conversations: EntityTable<MChatIIFEConversation, "sid">;
};

export interface MChatIIFEConversationsDB
  extends MChatIIFEConversationsDBType {}

export class MChatIIFEConversations {
  readonly #conversations: MChatIIFEConversation[];
  readonly #database: MChatIIFEConversationsDB;
  #bus: BusService | undefined;

  private constructor(database: MChatIIFEConversationsDB) {
    this.#conversations = [];
    this.#database = database;
    this.#update(false);
  }

  get conversations(): MChatIIFEConversation[] {
    return this.#conversations;
  }

  install(bus: BusService) {
    if (this.#bus) {
      return;
    }
    this.#bus = bus;
    this.#bus.on(
      "m-chat-iife:conversation:updated",
      this.#updateFromBus.bind(this),
      {
        crossTab: true,
      },
    );
    debug("Installed bus service");
  }

  update(
    sid: string,
    minimized: boolean,
    x: number,
    y: number,
    width: number,
    height: number,
    lastFocused: DateTime,
  ) {
    this.#database.conversations
      .put({
        sid,
        minimized,
        x,
        y,
        width,
        height,
        lastFocused: lastFocused.toISO()!,
      })
      .then(() => {
        this.#update(false);
      })
      .catch((e) => {
        debug(e);
      });
  }

  close(sid: string) {
    this.#database.conversations
      .delete(sid)
      .then(() => {
        this.#update(false);
      })
      .catch((e) => {
        debug(e);
      });
  }

  focus(sid: string) {
    this.#database.conversations
      .update(sid, {
        lastFocused: DateTime.now().toISO()!,
      })
      .then(() => {
        this.#update(false);
      })
      .catch((e) => {
        debug(e);
      });
  }

  async shutdown() {
    if (this.#bus) {
      this.#bus.off(
        "m-chat-iife:conversation:updated",
        this.#updateFromBus.bind(this),
      );
    }
    await this.#database.close();
  }

  #updateFromBus(from?: string) {
    if (from && from !== this.#bus!.uuid) {
      this.#update(false);
    }
  }

  async #update(fromRemote: boolean = true) {
    const conversations = await this.#database.conversations.toArray();
    while (this.#conversations.length) {
      this.#conversations.shift();
    }
    const toAdd = new Set<MChatIIFEConversation>();
    const toRemove = new Set<MChatIIFEConversation>();
    const toUpdate = new Set<MChatIIFEConversation>();
    conversations.forEach((conversation) => {
      const existing = this.#conversations.find(
        (c) => c.sid === conversation.sid,
      );
      if (existing) {
        const existingJson = JSON.stringify(existing);
        const currentJson = JSON.stringify(conversation);
        if (existingJson !== currentJson) {
          toUpdate.add(conversation);
        }
      } else {
        toAdd.add(conversation);
      }
    });
    this.#conversations.forEach((conversation) => {
      const exists = conversations.some((c) => c.sid === conversation.sid);
      if (!exists) {
        toRemove.add(conversation);
      }
    });
    toAdd.forEach((conversation) => {
      this.#conversations.push(conversation);
    });
    toRemove.forEach((conversation) => {
      const index = this.#conversations.findIndex(
        (c) => c.sid === conversation.sid,
      );
      if (index !== -1) {
        this.#conversations.splice(index, 1);
      }
    });
    toUpdate.forEach((conversation) => {
      const index = this.#conversations.findIndex(
        (c) => c.sid === conversation.sid,
      );
      if (index !== -1) {
        this.#conversations.splice(index, 1, conversation);
      }
    });
    if (!fromRemote) {
      debug("Update triggered from local");
      if (this.#bus) {
        this.#bus.emit("m-chat-iife:conversation:updated", {
          local: true,
          crossTab: true,
        });
      }
    } else {
      debug("Update triggered from remote");
    }
  }

  public static async init(
    namespace: string,
    _psk: string,
  ): Promise<MChatIIFEConversations> {
    const db: MChatIIFEConversationsDB = new Dexie(
      namespace,
    ) as MChatIIFEConversationsDB;
    await db.version(1).stores({
      conversations: "sid",
    });
    await db.open();
    const instance = new MChatIIFEConversations(db);
    return instance;
  }
}
