<template>
  <v-app
    ref="app"
    class="m-chat-iife-app-container"
    @mousemove="onMouseMove"
    @mouseup="onMouseUp"
    @mouseleave="onMouseLeave"
    @touchmove="onTouchMove"
    @touchend="onTouchEnd"
    @touchcancel="onTouchCancel"
  >
    <div v-if="debug.value" class="m-chat-iife-app-debug">
      <pre :key="dbUpdateKey" v-text="JSON.stringify(conversations, null, 2)" />
    </div>
    <Malarkey />
    <div
      v-if="showActionMenu"
      v-bind="actionMenuBindings"
      ref="actionMenuElement"
    >
      <v-badge
        :model-value="showActionMenu && updateable"
        icon="mdi-exclamation-thick"
        color="warning"
      >
        <v-card v-bind="actionMenuCardBindingsInternal" @click="openActionMenu">
          <v-toolbar flat height="25">
            <v-toolbar-title>
              {{ customizations.app.name }}
            </v-toolbar-title>
          </v-toolbar>
          <v-divider v-if="updateable && actionMenuOpen" />
          <v-sheet color="warning" rounded="0">
            <v-list-item
              v-if="updateable && actionMenuOpen && !appUpdateInProgress"
              :title="$t('mMalarkeyAppUpdateSnackbar.title')"
              subtitle="Click here to update & reload"
              prepend-icon="mdi-alert-circle-outline"
              two-line
              @click="onUpdateApp"
            />
            <v-list-item
              v-if="updateable && actionMenuOpen && appUpdateInProgress"
              title="Chat App Update in Progress"
              subtitle="The page will reload momentarily"
              prepend-icon="mdi-alert-circle-outline"
              two-line
            />
          </v-sheet>
          <!-- <v-alert
            v-if="updateable && actionMenuOpen"
            type="warning"
            color="warning"
            rounded="0"
          >
            <span v-text="$t('mMalarkeyAppUpdateSnackbar.title')" />
          </v-alert> -->
          <v-divider v-if="actionMenuOpen" />
          <v-expand-transition>
            <template v-if="identified">
              <div v-show="actionMenuOpen">
                <v-list class="py-0">
                  <v-list-item
                    prepend-icon="mdi-pencil-box-outline"
                    title="Start New Conversation"
                    @click="displayStartChat"
                  />
                </v-list>
                <v-divider />
                <MalarkeyConversationList
                  v-model="conversationSidSelected"
                  class="py-0 m-conversation-list"
                  @sid="onConversationSid"
                />
                <v-divider v-if="allowLoginWithoutAuth.value" />
                <v-list v-if="allowLoginWithoutAuth.value" class="py-0">
                  <v-list-item :title="$t('general.logout')" @click="logout" />
                </v-list>
              </div>
            </template>
            <template v-else>
              <v-list v-show="actionMenuOpen" class="py-0">
                <v-list-item
                  :title="$t('malarkeyLogin.general.login')"
                  @click="displayLogin"
                  @sid="onConversationSid"
                />
              </v-list>
            </template>
          </v-expand-transition>
        </v-card>
      </v-badge>
    </div>
    <v-dialog v-model="showLoginDialog" v-bind="loginDialogBindings">
      <div class="m-chat-iife-app-login-dialog">
        <v-fab
          v-bind="loginDialogFabBindings"
          @click="showLoginDialog = false"
        />
        <MalarkeyLogin v-bind="malarkeyLoginBindings" />
      </div>
    </v-dialog>
    <v-dialog v-model="showStartChat" v-bind="startChatDialogBindings">
      <MalarkeyStartChat
        @cancel="showStartChat = false"
        @sid="onConversationSid"
      />
    </v-dialog>
    <!-- @vue-ignore -->
    <template v-for="(c, i) in conversations" :key="[c.sid, i].join('-')">
      <ChatWindow
        v-if="!c.minimized"
        v-bind="c"
        :index="getSortIndexOfSid(c.sid)"
        :app="app"
        @focus="onChatFocus"
        @close="onChatClose"
        @minimize="onChatMinimize"
        @resize="onChatResize"
      />
      <ChatHead
        v-if="c.minimized"
        v-bind="c"
        :index="getSortIndexOfSid(c.sid)"
        :size="chatHeadSize.value"
        @focus="onChatFocus"
        @close="onChatClose"
        @maximize="onChatMaximize"
        @move="onChatMove"
      />
    </template>
  </v-app>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
  inject,
  onMounted,
  onBeforeUnmount,
  watch,
  nextTick,
} from "vue";
import { useVueprint } from "@jakguru/vueprint/utilities";
import { getDebugger } from "@jakguru/vueprint/utilities/debug";
import { defined } from "@nhtio/malarkey-client-core/utilities/flow";
import { isRecordObject } from "@nhtio/malarkey-client-core/utilities/helpers";
import { DateTime } from "luxon";
import Joi from "joi";
import ChatWindow from "./components/chatWindow.vue";
import ChatHead from "./components/chatHead.vue";

import type { PropType, Ref, ComputedRef } from "vue";
import type {
  IdentityService,
  BusService,
  ApiService,
  CronService,
  PushService,
} from "@jakguru/vueprint";
import type { MalarkeyTwilioAdapter } from "@nhtio/malarkey-client-core/twilio/malarkeyTwilioAdapter";
import type {
  BrandCustomizationManifest,
  BrandAudioFiles,
  BrandVisualFiles,
} from "@nhtio/malarkey-client-customization-validator";
import type {
  MChatIIFEConversations,
  MChatIIFEConversation,
} from "./m-chat-iife-conversations";
import type { IIFEBus } from "./m-chat-iife-bus";
import type { VApp } from "vuetify/lib/components/VApp/index.mjs";
import type { Message } from "@twilio/conversations";

const debug = getDebugger("IIFE", "#FFFFFF", "#E34C26");

const isValidIdentityFeedback = (value: unknown) => {
  const schema = Joi.object({
    bearer: Joi.string().required(),
    expiration: Joi.string().isoDate().required(),
    identity: Joi.string().required(),
    type: Joi.string().allow("agent", "client").required(),
    username: Joi.string().required(),
  }).unknown(true);
  const { error } = schema.validate(value);
  return !error;
};

export default defineComponent({
  name: "MChatIIFE",
  components: {
    ChatWindow,
    ChatHead,
  },
  props: {
    allowLoginWithoutAuth: {
      type: Object as PropType<ComputedRef<boolean>>,
      default: () => computed(() => false),
    },
    actionMenuStyling: {
      type: Object as PropType<ComputedRef<Partial<CSSStyleDeclaration>>>,
      default: () => computed(() => ({})),
    },
    actionMenuCardBindings: {
      type: Object as PropType<ComputedRef<any>>,
      default: () => computed(() => ({})),
    },
    chatHeadSize: {
      type: Object as PropType<ComputedRef<number>>,
      default: () => computed(() => 40),
    },
    showByDefault: {
      type: Object as PropType<ComputedRef<boolean>>,
      default: () => computed(() => true),
    },
    customizations: {
      type: Object as PropType<BrandCustomizationManifest>,
      required: true,
    },
    audioFiles: {
      type: Object as PropType<BrandAudioFiles>,
      required: true,
    },
    visualFiles: {
      type: Object as PropType<BrandVisualFiles>,
      required: true,
    },
    debug: {
      type: Object as PropType<ComputedRef<boolean>>,
      default: () => computed(() => false),
    },
    database: {
      type: Object as PropType<MChatIIFEConversations>,
      required: true,
    },
    bus: {
      type: Object as PropType<IIFEBus>,
      required: true,
    },
  },
  setup(props) {
    const allowLoginWithoutAuth = computed(
      () => props.allowLoginWithoutAuth.value,
    );
    const actionMenuStyling = computed(() => props.actionMenuStyling.value);
    const customizations = computed(() => props.customizations);
    const visualFiles = computed(() => props.visualFiles);
    const showByDefault = computed(() => props.showByDefault.value);
    const shouldShow = ref(showByDefault.value);
    const { database } = props;
    const dbUpdateTimestamp = ref(DateTime.utc().valueOf());
    const dbUpdateKey = computed(
      () => `m-chat-iife-${dbUpdateTimestamp.value}`,
    );
    const { booted, updateable } = useVueprint();
    const identity = inject<IdentityService>("identity");
    const bus = inject<BusService>("bus");
    const twilio = inject<MalarkeyTwilioAdapter>("twilio");
    const http = inject<ApiService>("http");
    const cron = inject<CronService>("cron");
    const push = inject<PushService>("push");
    defined(bus, undefined, [undefined, null]).then((b) => {
      if (b) {
        database.install(b);
      }
    });
    const onMChatIifeConversationUpdated = () => {
      if (!bus) {
        return;
      }
      dbUpdateTimestamp.value = DateTime.utc().valueOf();
      debug("Conversations updated");
    };
    const identified = computed(() => {
      if (!booted.value || !identity) {
        return false;
      }
      return identity.identified.value === true;
    });
    const conversations = computed(() =>
      dbUpdateTimestamp.value > 0 && identified.value === true
        ? [...database.conversations].filter((c) => {
            if (!twilio) {
              return false;
            }
            return twilio.conversations.value.some((tc) => tc.sid === c.sid);
          })
        : [],
    );
    const getSortIndexOfSid = (sid: string) => {
      const sorted = [...database.conversations].sort((a, b) => {
        const lastFocusedA =
          "string" === typeof a.lastFocused
            ? DateTime.fromISO(a.lastFocused)
            : -1;
        const lastFocusedB =
          "string" === typeof b.lastFocused
            ? DateTime.fromISO(b.lastFocused)
            : -1;
        return lastFocusedA > lastFocusedB ? 1 : -1;
      });
      return sorted.findIndex((c) => c.sid === sid);
    };
    const showActionMenu = computed(
      () =>
        (identified.value || allowLoginWithoutAuth.value) && shouldShow.value,
    );
    const actionMenuBindings: any = computed(() => ({
      style: actionMenuStyling.value,
      class: ["m-chat-iife-action-menu"],
    }));
    const actionMenuElement = ref<HTMLElement | null>(null);
    const actionMenuOpen = ref(false);
    const actionMenuCardBindingsInternal = computed(() => ({
      ...props.actionMenuCardBindings.value,
      ripple: !!actionMenuOpen.value,
    }));
    const openActionMenu = () => {
      actionMenuOpen.value = true;
    };
    const closeActionMenu = () => {
      actionMenuOpen.value = false;
    };
    const closeIfClickOutsideActionMenu = (event: MouseEvent) => {
      let close: boolean = true;
      if (actionMenuElement.value) {
        const rect = actionMenuElement.value.getBoundingClientRect();
        const x = event.clientX;
        const y = event.clientY;
        if (
          x >= rect.left &&
          x <= rect.right &&
          y >= rect.top &&
          y <= rect.bottom
        ) {
          close = false;
        }
      }
      if (close) {
        closeActionMenu();
      }
    };
    const showLoginDialogRef = ref(false);
    const showLoginDialog = computed({
      get() {
        return !identified.value && showLoginDialogRef.value;
      },
      set(value: boolean) {
        showLoginDialogRef.value = value;
      },
    });
    const displayLogin = () => {
      actionMenuOpen.value = false;
      showLoginDialog.value = true;
    };
    const logout = (e: Event) => {
      e.preventDefault();
      if (identity) {
        identity.logout();
      }
    };
    const loginDialogBindings = computed(() => ({
      maxWidth: "400px",
      scrim: "surface",
      opacity: 0.83,
    }));
    const loginDialogFabBindings = computed(
      () =>
        ({
          icon: "mdi-close",
          color: "primary",
          absolute: true,
          location: "top right",
          style: {
            top: "-24px",
            right: "-24px",
          },
        }) as any,
    );
    const malarkeyLoginBindings = computed(() => ({
      type: "client",
      logo: visualFiles.value.logoColor,
      appName: customizations.value.app.name,
      brandName: customizations.value.brand.name,
    }));
    const showStartChatRef = ref(false);
    const showStartChat = computed({
      get() {
        return identified.value && showStartChatRef.value;
      },
      set(value: boolean) {
        showStartChatRef.value = value;
      },
    });
    const displayStartChat = () => {
      actionMenuOpen.value = false;
      showStartChat.value = true;
    };
    const startChatDialogBindings = computed(() => ({
      closeOnBack: false,
      closeOnContentClick: false,
      maxWidth: 400,
      retainFocus: false,
      persistent: true,
      scrim: "surface",
      opacity: 0.83,
    }));

    const fixExistingEntity = (existing: MChatIIFEConversation) => {
      if (existing.y < 0) {
        existing.y = 0;
      }
      if (existing.x < 0) {
        existing.x = 0;
      }
      if (existing.y > window.innerHeight) {
        existing.y = window.innerHeight - 100;
      }
      if (existing.x > window.innerWidth) {
        existing.x = window.innerWidth - 100;
      }
      return existing;
    };
    const conversationSidSelected = ref("");
    const onConversationSid = (sid: string) => {
      conversationSidSelected.value = sid;
      actionMenuOpen.value = false;
      showStartChat.value = false;
      const existing = database.conversations.find((c) => c.sid === sid);
      if (existing) {
        const fixed = fixExistingEntity(existing);
        debug("Focus on existing conversation", existing);
        database.update(
          sid,
          false,
          fixed.x,
          fixed.y,
          fixed.width,
          fixed.height,
          DateTime.utc(),
        );
      } else {
        // open a new conversation with a width of 450px and a height of 350px in an unminimized state in the center of the screen
        debug("Opening a new conversation");
        if (typeof window === "undefined" || !database) {
          return;
        }
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const x = (windowWidth - 450) / 2;
        const y = (windowHeight - 350) / 2;
        database.update(sid, false, x, y, 450, 350, DateTime.utc());
      }
      debug(`Trying to open conversation ${sid}`);
      nextTick(() => {
        conversationSidSelected.value = "";
      });
    };

    const onChatClose = (sid: string) => {
      if (!database) {
        return;
      }
      debug(`Closing conversation ${sid}`);
      database.close(sid);
    };
    const onChatFocus = (sid: string) => {
      if (
        !database ||
        "undefined" === typeof window ||
        "undefined" === typeof document
      ) {
        return;
      }
      let existing = database.conversations.find((c) => c.sid === sid);
      if (existing) {
        existing = fixExistingEntity(existing);
      }
      /**
       * Determine if there is anything obscuring the element. If there isn't, then there's no need to refocus.
       */
      const chatElem = document.getElementById(`m-iife-chat-${sid}`);
      if (!chatElem) {
        debug(`could not find #m-iife-chat-${sid}`);
        return;
      }
      debug(`Checking for conversations overlapping ${sid}`, chatElem);
      const rect = chatElem.getBoundingClientRect();
      const chatElements = document.querySelectorAll(
        ".m-iife-chat-window, .m-iife-chat-head",
      );
      let overlapping = false;
      chatElements.forEach((ce) => {
        if (ce.id === `m-iife-chat-${sid}`) {
          return;
        }
        const ceRect = ce.getBoundingClientRect();
        if (
          rect.left < ceRect.right &&
          rect.right > ceRect.left &&
          rect.top < ceRect.bottom &&
          rect.bottom > ceRect.top
        ) {
          overlapping = true;
        }
      });
      if (!overlapping) {
        debug(`No overlapping conversations found for ${sid}`);
        return;
      }
      if (!existing) {
        return;
      }
      debug(`Focusing conversation ${sid}`);
      database.focus(sid);
    };
    const onChatMinimize = (sid: string) => {
      if (!database) {
        return;
      }
      const existing = database.conversations.find((c) => c.sid === sid);
      if (!existing) {
        return;
      }
      debug(`Minimizing conversation ${sid}`);
      database.update(
        sid,
        true,
        existing.x,
        existing.y,
        existing.width,
        existing.height,
        DateTime.utc(),
      );
    };
    const onChatMaximize = (sid: string) => {
      if (!database) {
        return;
      }
      const existing = database.conversations.find((c) => c.sid === sid);
      if (!existing) {
        return;
      }
      debug(`Maximizing conversation ${sid}`);
      const fixed = fixExistingEntity(existing);
      database.update(
        sid,
        false,
        fixed.x,
        fixed.y,
        fixed.width,
        fixed.height,
        DateTime.utc(),
      );
    };
    const onChatMove = (sid: string, x: number, y: number) => {
      if (!database) {
        return;
      }
      const existing = database.conversations.find((c) => c.sid === sid);
      if (!existing) {
        return;
      }
      /**
       * Make sure there's actually a change in position before updating the database.
       */
      if (existing.x === x && existing.y === y) {
        debug(`No change in position for conversation ${sid}`);
        return onChatFocus(sid);
      }
      debug(`Moving conversation ${sid} to ${x}, ${y}`);
      database.update(
        sid,
        existing.minimized,
        x,
        y,
        existing.width,
        existing.height,
        DateTime.utc(),
      );
    };
    const onChatResize = (
      sid: string,
      width: number,
      height: number,
      x: number,
      y: number,
    ) => {
      if (!database) {
        return;
      }
      const existing = database.conversations.find((c) => c.sid === sid);
      if (!existing || existing.minimized) {
        return;
      }
      if (width < 300 || height < 152) {
        debug(`Resizing stopped due to invalid valus ${sid}`, {
          width,
          height,
          x,
          y,
        });
        return;
      }
      debug(`Resizing conversation ${sid}`, { width, height, x, y });
      database.update(
        sid,
        existing.minimized,
        x,
        y,
        width,
        height,
        DateTime.utc(),
      );
    };

    const onDoActionMenuOpen = () => {
      actionMenuOpen.value = true;
    };
    const onDoActionMenuClose = () => {
      actionMenuOpen.value = false;
    };
    const onDoActionMenuToggle = () => {
      actionMenuOpen.value = !actionMenuOpen.value;
    };
    const onDoActionMenuShow = () => {
      shouldShow.value = true;
    };
    const doOnActionMenuHide = () => {
      shouldShow.value = false;
    };

    let authenticationCommandAbortController: AbortController | undefined;
    const onDoAuthenticationLogin = (encryptedCustomerId: string) => {
      if (
        "string" !== typeof encryptedCustomerId ||
        encryptedCustomerId.trim().length === 0
      ) {
        debug("Invalid encryptedCustomerId", encryptedCustomerId);
        return;
      }
      if (authenticationCommandAbortController) {
        authenticationCommandAbortController.abort();
      }
      authenticationCommandAbortController = new AbortController();
      defined(http, authenticationCommandAbortController.signal, [
        undefined,
        null,
      ])
        .then(async (h) => {
          if (h) {
            const i = await defined(
              identity,
              authenticationCommandAbortController
                ? authenticationCommandAbortController.signal
                : undefined,
              [undefined, null],
            );
            if (!i) {
              return;
            }
            try {
              const { status, data } = await h.post(
                "/identity/from-encrypted",
                {
                  encrypted: encryptedCustomerId,
                },
                {
                  signal: authenticationCommandAbortController
                    ? authenticationCommandAbortController.signal
                    : undefined,
                  validateStatus: () => true,
                },
              );
              if (
                201 === status &&
                isRecordObject(data) &&
                "data" in data &&
                isValidIdentityFeedback(data.data)
              ) {
                const {
                  bearer,
                  expiration,
                  identity: id,
                  type,
                  username,
                } = data.data!;
                i.login(bearer, expiration, {
                  id,
                  type,
                  username,
                });
              } else {
                debug("Failed to login", status, data);
                return;
              }
            } catch {
              // do nothing since this only occurs when the request is aborted
            }
          }
        })
        .catch(() => {});
    };
    const onDoAuthenticationLogout = () => {
      if (authenticationCommandAbortController) {
        authenticationCommandAbortController.abort();
      }
      authenticationCommandAbortController = new AbortController();
      defined(identity, authenticationCommandAbortController.signal, [
        undefined,
        null,
      ])
        .then(async (i) => {
          if (i) {
            i.logout();
          }
        })
        .catch(() => {});
    };

    const app: Ref<VApp | null> = ref<VApp | null>(null);
    const onMouseMove = (event: MouseEvent) => {
      if (bus) {
        // @ts-ignore - typescript seems to be stupid right now
        bus.emit("m-chat-iife:app:mousemove", { local: true }, event);
      }
    };
    const onMouseUp = (event: MouseEvent) => {
      if (bus) {
        // @ts-ignore - typescript seems to be stupid right now
        bus.emit("m-chat-iife:app:mouseup", { local: true }, event);
      }
    };
    const onMouseLeave = (event: MouseEvent) => {
      if (bus) {
        // @ts-ignore - typescript seems to be stupid right now
        bus.emit("m-chat-iife:app:mouseleave", { local: true }, event);
      }
    };
    const onTouchMove = (event: TouchEvent) => {
      if (bus) {
        // @ts-ignore - typescript seems to be stupid right now
        bus.emit("m-chat-iife:app:touchmove", { local: true }, event);
      }
    };
    const onTouchEnd = (event: TouchEvent) => {
      if (bus) {
        // @ts-ignore - typescript seems to be stupid right now
        bus.emit("m-chat-iife:app:touchend", { local: true }, event);
      }
    };
    const onTouchCancel = (event: TouchEvent) => {
      if (bus) {
        // @ts-ignore - typescript seems to be stupid right now
        bus.emit("m-chat-iife:app:touchcancel", { local: true }, event);
      }
    };

    const onNewIncomingMessage = (message: Message) => {
      // if the message is not a reaction, and the conversation is not opened, then open the conversation
      if (
        "object" === typeof message.attributes &&
        null !== message.attributes
      ) {
        const reactionTo = (message.attributes as any).reactionTo;
        if ("string" === typeof reactionTo && reactionTo.trim().length > 0) {
          return;
        }
      }
      if (!database) {
        return;
      }
      const existing = database.conversations.find(
        (c) => c.sid === message.conversation.sid,
      );
      if (existing) {
        return;
      }
      onConversationSid(message.conversation.sid);
    };

    let checkForUnreadMessagesWhileUnloadedAbortController:
      | AbortController
      | undefined;
    const checkForUnreadMessagesWhileUnloaded = async () => {
      debug(
        "Checking for unread messages which were sent while application wasn't loaded",
      );
      if (checkForUnreadMessagesWhileUnloadedAbortController) {
        checkForUnreadMessagesWhileUnloadedAbortController.abort();
      }
      checkForUnreadMessagesWhileUnloadedAbortController =
        new AbortController();
      const [t, b] = await Promise.all([
        defined(
          twilio,
          checkForUnreadMessagesWhileUnloadedAbortController.signal,
          [undefined, null],
        ),
        defined(
          bus,
          checkForUnreadMessagesWhileUnloadedAbortController.signal,
          [undefined, null],
        ),
      ]);
      if (!t || !b) {
        debug("Twilio or Bus is missing");
        return;
      }
      debug("Waiting for Twilio to finish initializing");
      await Promise.race([
        // 15 second timeout for the other promises to resolve
        new Promise((resolve) => setTimeout(resolve, 15000)),
        // Resolve once a "twilio:client:initialized" event is emitted
        new Promise((resolve) => {
          b.once(
            // @ts-ignore - typescript seems to be stupid right now
            "twilio:client:initialized",
            () => {
              setTimeout(resolve, 3000);
            },
            { local: true },
          );
          if (checkForUnreadMessagesWhileUnloadedAbortController) {
            checkForUnreadMessagesWhileUnloadedAbortController.signal.addEventListener(
              "abort",
              () => {
                resolve(void 0);
              },
            );
          } else {
            // Resolve the promise after 15 seconds
            // In order to prevent the promise from hanging indefinitely
            setTimeout(resolve, 15000);
          }
        }),
        // Resolve on the abort signal
        new Promise((resolve) => {
          if (checkForUnreadMessagesWhileUnloadedAbortController) {
            checkForUnreadMessagesWhileUnloadedAbortController.signal.addEventListener(
              "abort",
              () => {
                resolve(void 0);
              },
            );
          } else {
            // Resolve the promise after 15 seconds
            // In order to prevent the promise from hanging indefinitely
            setTimeout(resolve, 15000);
          }
        }),
      ]);
      debug("Twilio has finished initializing");
      if (checkForUnreadMessagesWhileUnloadedAbortController.signal.aborted) {
        return;
      }
      // send the abort signal so that the rest of the promises can be resolved if they haven't already
      checkForUnreadMessagesWhileUnloadedAbortController.abort();
      if (t.conversations.value.length === 0) {
        // if there are no conversations, then there are no unread messages
        debug("No conversations to check for unread messages");
        return;
      }
      debug(
        `There are ${t.conversations.value.length} conversations to check for unread messages`,
      );
      const conversationsWithUnreadMessageCounts = Object.assign(
        {},
        ...(await Promise.all(
          t.conversations.value.map(async (c) => {
            try {
              const count = await c.getUnreadMessagesCount();
              return { [c.sid]: count };
            } catch {
              return { [c.sid]: 0 };
            }
          }),
        )),
      );
      for (const sid in conversationsWithUnreadMessageCounts) {
        if (
          conversationsWithUnreadMessageCounts[sid] === 0 ||
          !database ||
          database.conversations.some((c) => c.sid === sid)
        ) {
          debug(
            `Skipping conversation ${sid} with ${conversationsWithUnreadMessageCounts[sid]} unread messages`,
          );
          continue;
        }
        debug(
          `Opening conversation ${sid} with ${conversationsWithUnreadMessageCounts[sid]} unread messages`,
        );
        onConversationSid(sid);
      }
    };

    watch(
      () => identified.value,
      (is) => {
        if (is) {
          checkForUnreadMessagesWhileUnloaded();
        }
      },
      { immediate: true },
    );

    const appUpdateInProgress = ref(false);
    const onUpdateApp = async () => {
      if (push) {
        appUpdateInProgress.value = true;
        await push.update();
        appUpdateInProgress.value = false;
      }
    };

    let busDefinedAbortController: AbortController | undefined;
    onMounted(() => {
      props.bus.install(window);
      props.bus.on("do:action-menu:open", onDoActionMenuOpen);
      props.bus.on("do:action-menu:close", onDoActionMenuClose);
      props.bus.on("do:action-menu:toggle", onDoActionMenuToggle);
      props.bus.on("do:action-menu:show", onDoActionMenuShow);
      props.bus.on("do:action-menu:hide", doOnActionMenuHide);
      props.bus.on("do:authentication:login", onDoAuthenticationLogin);
      props.bus.on("do:authentication:show", displayLogin);
      props.bus.on("do:authentication:logout", onDoAuthenticationLogout);
      if (window) {
        window.addEventListener("click", closeIfClickOutsideActionMenu);
      }
      if (busDefinedAbortController) {
        busDefinedAbortController.abort();
      }
      busDefinedAbortController = new AbortController();
      defined(bus, busDefinedAbortController.signal, [undefined, null]).then(
        (b) => {
          if (b) {
            b.on(
              // @ts-ignore - typescript seems to be stupid right now
              "m-chat-iife:conversation:updated",
              onMChatIifeConversationUpdated,
              { local: true },
            );
            b.on(
              // @ts-ignore - typescript seems to be stupid right now
              "twilio:client:messageAdded",
              onNewIncomingMessage,
              { local: true },
            );
          }
        },
      );
      if (cron) {
        cron.start();
      }
    });
    onBeforeUnmount(() => {
      props.bus.off("do:action-menu:open", onDoActionMenuOpen);
      props.bus.off("do:action-menu:close", onDoActionMenuClose);
      props.bus.off("do:action-menu:toggle", onDoActionMenuToggle);
      props.bus.off("do:action-menu:show", onDoActionMenuShow);
      props.bus.off("do:action-menu:hide", doOnActionMenuHide);
      props.bus.off("do:authentication:login", onDoAuthenticationLogin);
      props.bus.off("do:authentication:logout", onDoAuthenticationLogout);
      props.bus.uninstall(window);
      if (window) {
        window.removeEventListener("click", closeIfClickOutsideActionMenu);
      }
      if (busDefinedAbortController) {
        busDefinedAbortController.abort();
      }
      if (bus) {
        bus.off(
          // @ts-ignore - typescript seems to be stupid right now
          "m-chat-iife:conversation:updated",
          onMChatIifeConversationUpdated,
          { local: true },
        );
        bus.off(
          // @ts-ignore - typescript seems to be stupid right now
          "twilio:client:messageAdded",
          onNewIncomingMessage,
          { local: true },
        );
      }
      if (cron) {
        cron.stop();
      }
    });
    return {
      dbUpdateKey,
      updateable,
      identified,
      showActionMenu,
      actionMenuBindings,
      actionMenuElement,
      actionMenuOpen,
      actionMenuCardBindingsInternal,
      openActionMenu,
      showLoginDialog,
      displayLogin,
      logout,
      loginDialogBindings,
      loginDialogFabBindings,
      malarkeyLoginBindings,
      showStartChat,
      startChatDialogBindings,
      displayStartChat,
      conversationSidSelected,
      onConversationSid,
      conversations,
      onChatFocus,
      onChatClose,
      onChatMinimize,
      onChatMaximize,
      onChatMove,
      onChatResize,
      getSortIndexOfSid,
      app,
      onMouseMove,
      onMouseUp,
      onMouseLeave,
      onTouchMove,
      onTouchEnd,
      onTouchCancel,
      appUpdateInProgress,
      onUpdateApp,
    };
  },
});
</script>
